requirejs.config({
    baseUrl: '/assets/javascripts',
    shim: {
        'backbone': {
            deps: ["underscore", "jquery"],
            exports: 'Backbone'
        },
        'bootstrap': {
            deps: ["jquery"]
        },
        'underscore': {
            deps: ["jquery"],
            exports: '_'
        },
        'jquery-typing': {
            deps: ["jquery"]
        },
        'typeahead': {
            deps: ["jquery"],
            init: function ($) {
                return require.s.contexts._.registry['typeahead.js'].factory( $ );
            }
        }
    },
    paths: {
        backbone: '../lib/backbonejs/backbone',
        bootstrap: '../lib/bootstrap/dist/js/bootstrap.bundle',
        underscore: '../lib/underscore/underscore',
        jquery: '../lib/jquery/jquery',
        bowser: '../lib/bowser/bowser',
        text: '../lib/requirejs-text/text',
        typeahead: '../lib/typeaheadjs/typeahead.jquery',
        qtip2: '../lib/qtip2/jquery.qtip',
        "jquery-typing": '../lib/github-com-ccakes-jquery-typing/plugin/jquery.typing-0.3.3',
        app: 'app'
    }
});

define("config", function(){});

